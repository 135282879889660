

@font-face {
 font-family: 'GTWalsheimPro-Bold';
 src: url('/public/fonts/GTWalsheimPro-Bold.ttf');
 font-weight: 500;
 font-style: normal;
 font-display: swap;
}
@font-face {
 font-family: 'GTWalsheimPro-Regular';
 src: url('/public/fonts/GTWalsheimPro-Regular.ttf');
 font-weight: 500;
 font-style: normal;
 font-display: swap;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.card-hover {
  overflow: hidden !important;
}

.img-card {
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.img-card:hover {
  transform: scale(1.1);
}

.scrollX ::-webkit-scrollbar {
  display: none !important;
}

.scrollX {
  -ms-overflow-style: none !important;
  scrollbar-width: 0 !important;
}

input:focus {
  outline: none !important;
  box-shadow: none !important;
  outline-offset: 0 !important;
}

.invert-filter {
  filter: invert(100%);
  -webkit-filter: invert(100%);
}

.phoneInput {
  background-color: #61dafb;
}


.followFlex .unfollow {
  display: none;
}

.followFlex:hover .following {
  display: none;
}

.followFlex:hover .unfollow {
  display: flex;
}